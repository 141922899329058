








import { Component, Vue } from "vue-property-decorator";

@Component
export default class Home extends Vue {
  private menuList: any = [
    { name: "Home", icon: "mdi-home", linkName: "home" },
    { name: "Logout", icon: "mdi-logout", linkName: "logout" },
    { name: "AccountingFirm", icon: "mdi-wrench", linkName: "accountingFirmTable" },
  ];
}
